:root {
  --blossom: #faebf3;
  --pretty: #eebcd6;
  --magenta: #c54e94;
  --bubblegum: #e889c0;
}

html {
  font-size: 16px;
  font-weight: 400;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  background-color: var(--bubblegum);
  background-image: url("./assets/juggle-club-background.png");
  background-repeat: repeat;
  background-size: 180px;
  background-position: center;
  color: white;
}

.App {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 30px 10px;
}

.profile-pic {
  border: 2px solid var(--blossom);
  border-radius: 100%;
  width: 100px;
}

.logo-type {
  width: 300px;
  margin: 30px 0px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

button {
  width: 100%;
  margin: 10px 0px;
  background-color: var(--magenta);
  border: 1px solid var(--blossom);
  color: var(--blossom);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: left;
  font-family: Roboto Mono, monospace;
  font-weight: 400;
}

button > * {
  padding: 10px;
  margin: 0;
}

p {
  display: flex;
  align-items: left;
  justify-content: left;
  font-size: 1rem;
}

a {
  text-decoration: none;
}
